'use client';
import { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Icon,
  MobileHeader,
  MobileHeaderBackButton,
  MessageProvider,
  UIIconType,
} from '@ui/components';
import { CreatorProfile } from './creator-profile';
import { CreatorConversations } from 'app/(app)/creator/_components/creator-conversations';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import { MobileHeaderUser } from '@components/mobile-header-user';
import { sendGAEvent } from '@next/third-parties/google';
import { useAppDispatch, useAppSelector } from '@lib/redux/store';
import { setCurrentTab, setScrollPosition } from '@lib/redux/badges/badges';
import { ImagePreviewProvider } from '@ui/hooks/file/ImagePreviewContext';
import { ROUTES } from '@constants/routes.constants';
import { useCreatorMessagesState } from 'app/(app)/creator/_components/creator-messages';
import {
  useGetNotificationByTypeQuery,
  useGetNotificationsQuery,
  useMarkAllAsReadMutation,
  useUpdateNotificationMutation,
} from '@lib/redux/api/notification-api';
import { NotificationPanel } from '@notification/component';
import { useChallengeState } from 'app/(app)/creator/challenges/challenge.state';
import { useNotebookState } from 'app/(app)/creator/contents/notebook.state';
import { useNavgatorState } from './navigator.state';
import { setCreatorId, setShowMessages } from '@lib/redux/features/user';
import { useAuthSession } from '@lib/hooks/use-auth-session';
import WithAuthModal from '@components/with-auth-modal';
import { useInteractionGuard } from '@interaction-guard/index';

interface TabDefinition {
  id: string;
  type: 'component' | 'route';
  path?: string;
  icon?: UIIconType;
  notificationGetter?: (data: any) => number;
}

export const TABS: TabDefinition[] = [
  { id: 'Badges', type: 'component', icon: 'BadgeIcon' },
  {
    id: 'Leaderboards',
    type: 'component',
    icon: 'RankingIcon',
  },
  {
    id: 'Community',
    type: 'route',
    path: ROUTES.community,
    icon: 'leaderboard',
    notificationGetter: (data) => data?.newPostNotifications?.unread_count || 0,
  },
  {
    id: 'Content',
    type: 'route',
    path: ROUTES.contents,
    icon: 'gem',
    notificationGetter: (data) => data?.newContentNotifications?.unread_count || 0,
  },
  {
    id: 'Challenges',
    type: 'route',
    path: ROUTES.challenges,
    icon: 'challenge',
    notificationGetter: (data) => data?.newNotificationsChallenge?.unread_count || 0,
  },
];

const Navigator = ({ children }: React.PropsWithChildren) => {
  const { creatorId: creatorIdParam } = useParams();
  const { isAuthenticated } = useAuthSession();
  const currentTab = useAppSelector((state) => state.badges.currentTab);
  const searchParams = useSearchParams();
  const creatorId = searchParams.get('id');
  const tabParam = searchParams.get('tab');
  const [localTab, setLocalTab] = useState<string>(currentTab);
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { user: userInfo } = useAppSelector((state) => state.user);
  const router = useRouter();
  const [storedCreatorId, setStoredCreatorId] = useState<string | null>(null);
  const { isIntercepted, message, pendingFn, clearInterception } = useInteractionGuard();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const {
    isBadgesReady,
    isBadgesLoading,
    isAllBadgesLoading,
    creator,
    isCreatorLoading,
    badgesData,
    user,
    isUserLoading,
    isMessagesLoading,
    setMessagesLoading,
    onBack,
    isCommunityConversationSuccess,
    isCommunityConversationLoading,
    isPublicCommunityConversationSuccess,
    isPublicCommunityConversationLoading,
    communitySets,
    publicCommunitySets,
    newPostNotifications,
    newContentNotifications,
    isSubscribed,
  } = useNavgatorState(
    userInfo?.creator_id || storedCreatorId || creatorId || creatorIdParam?.toString(),
  );
  const messageState = useCreatorMessagesState({ ordering: 'asc' });
  const { notebooks } = useNotebookState();

  const { data: newNotificationsChallenge } = useGetNotificationByTypeQuery(
    { user_id: user?.id, type: 'challenge', creator_id: userInfo?.creator_id },
    { skip: !user?.id || !userInfo?.creator_id || !isAuthenticated },
  );

  const notificationData = useMemo(
    () => ({
      newPostNotifications,
      newContentNotifications,
      newNotificationsChallenge,
    }),
    [newPostNotifications, newContentNotifications, newNotificationsChallenge],
  );

  const creatorHasCommunity = useMemo(() => {
    if (!isAuthenticated) {
      return (
        isPublicCommunityConversationSuccess &&
        !isPublicCommunityConversationLoading &&
        publicCommunitySets.length > 0 &&
        publicCommunitySets[0]?.last_message !== null
      );
    }

    return (
      isCommunityConversationSuccess &&
      !isCommunityConversationLoading &&
      communitySets.length > 0 &&
      communitySets[0]?.last_message !== null
    );
  }, [
    isCommunityConversationSuccess,
    isCommunityConversationLoading,
    communitySets.length,
    isPublicCommunityConversationSuccess,
    isPublicCommunityConversationLoading,
    publicCommunitySets.length,
  ]);
  const { challenges } = useChallengeState();
  const creatorChallenge = useMemo(
    () => challenges.find((c) => c.status === 'active' || c.status === 'archived'),
    [challenges],
  );

  const creatorHasContents = useMemo(() => {
    return notebooks.length > 0;
  }, [notebooks]);

  const filteredTabs = useMemo(() => {
    return TABS.filter((tab) => {
      if (tab.id === 'Content' && !creatorHasContents) return false;
      if (tab.id === 'Challenges' && !creatorChallenge) return false;
      if (tab.id === 'Community' && !creatorHasCommunity) return false;
      return true;
    });
  }, [creatorHasContents, creatorChallenge, creatorHasCommunity, isAuthenticated]);

  const isLoading = useMemo(() => {
    return (
      isCreatorLoading ||
      isBadgesLoading ||
      isAllBadgesLoading ||
      isCommunityConversationLoading ||
      isMessagesLoading ||
      isUserLoading ||
      isPublicCommunityConversationLoading
    );
  }, [
    isCreatorLoading,
    isBadgesLoading,
    isAllBadgesLoading,
    isCommunityConversationLoading,
    isMessagesLoading,
    isUserLoading,
    isPublicCommunityConversationLoading,
  ]);

  const handleTabClick = (tabId: string) => {
    const tab = TABS.find((t) => t.id === tabId);
    setLocalTab(tabId);
    dispatch(setCurrentTab(tab.id));
    dispatch(setScrollPosition(''));

    if (tab.type === 'route' && tab.path) {
      // For route tabs, navigate to the proper page
      const baseCommunitySet = isAuthenticated ? communitySets : publicCommunitySets;
      const additionalParam =
        tab.id === 'Community'
          ? `&slug=${baseCommunitySet[0]?.conversation?.id || ''}`
          : `id=${userInfo?.creator_id}`;
      setLocalTab(null);
      router.push(`${tab.path}?${additionalParam}`);
    } else {
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });
      router.push(`${ROUTES.creator}?id=${userInfo?.creator_id}&tab=${tab.id}`);
    }

    sendGAEvent('event', `${tab.id}TabClicked`, { creatorId });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && userInfo?.creator_id !== '') {
      window.sessionStorage.setItem('currentCreatorId', userInfo?.creator_id);
      const stored = window.sessionStorage.getItem('currentCreatorId');
      setStoredCreatorId(stored);
    }
    if (creatorId) {
      dispatch(setCreatorId(creatorId));
    }
  }, [creatorId]);

  useEffect(() => {
    if (currentTab === 'Badges' && localTab !== 'Badges') {
      setLocalTab('Badges');
      handleTabClick('Badges');
    }

    if (tabParam) {
      const foundTab = TABS.find((tab) => tab.id === tabParam);
      if (tabParam === 'About') {
        dispatch(setCurrentTab('About'));
        setLocalTab('About');
      } else if (foundTab) {
        setLocalTab(foundTab.id);
        dispatch(setCurrentTab(foundTab.id));
      }
      return;
    }

    const routeTab = TABS.find(
      (tab) => tab.type === 'route' && tab.path && pathname.startsWith(tab.path),
    );

    if (routeTab) {
      setLocalTab(routeTab.id);
      dispatch(setCurrentTab(routeTab.id));
      return;
    }
  }, [pathname, tabParam, currentTab, isAuthenticated]);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!initialLoad) {
      sendGAEvent('event', `${currentTab}TabClicked`, { creatorId });
    } else if (currentTab !== 'Badges') {
      sendGAEvent('event', `${currentTab}TabClicked`, { creatorId });
      setInitialLoad(false);
    } else {
      setInitialLoad(false);
    }
  }, [currentTab]);

  const openMessagePanel = () => {
    sendGAEvent('event', 'MessageButtonClicked', { creatorId });
    dispatch(setShowMessages(true));
  };

  function onOpenAbout() {
    dispatch(setCurrentTab('About'));
    router.push(`${ROUTES.creator}?id=${userInfo?.creator_id}&tab=About`);
    setLocalTab('About');
  }

  const handleSettingsRoute = () =>
    router.push(`${ROUTES.settingsMobile}/?tab=notification-preference`);

  const controlDisplay = useMemo(() => {
    let creatorProfile: boolean;
    if (pathname?.includes('fan-profile')) {
      creatorProfile = false;
    } else if (pathname?.includes('community-guidelines')) {
      creatorProfile = false;
    } else if (pathname?.split('/')?.length <= 4) {
      creatorProfile = true;
    } else {
      creatorProfile = false;
    }
    const isNotHome =
      pathname !== '/' &&
      pathname !== '/settings/' &&
      pathname !== '/faq/' &&
      pathname !== '/settings/mobile/';
    return { creatorProfile, isNotHome };
  }, [pathname]);

  useEffect(() => {
    if (isIntercepted) {
      if (isAuthenticated && pendingFn) {
        // Execute the function if authenticated
        pendingFn.fn(...pendingFn.args);
        clearInterception();
      } else if (!isAuthenticated) {
        setShowAuthModal(true);
      }
    }
  }, [isIntercepted, isAuthenticated]);

  return (
    <div
      className={`lg:bg-white bg-bgGrey lg:pb-0 ${controlDisplay.isNotHome ? 'pb-0' : ''} ${
        !isAuthenticated ? 'lg:max-w-[80vw] mx-auto' : ''
      }`}>
      {isAuthenticated && (
        <MessageProvider userType="fan" state={messageState}>
          <ImagePreviewProvider>
            <CreatorConversations
              onClose={() => dispatch(setShowMessages(false))}
              creator={creator}
              setMessagesLoading={setMessagesLoading}
              hasArchivedMessages={creator.hasArchivedMessages}
            />
          </ImagePreviewProvider>
        </MessageProvider>
      )}

      {isAuthenticated && controlDisplay.isNotHome && (
        <MobileHeader>
          <MobileHeaderBackButton isDark={false} onBack={onBack} />
          <div className="flex items-center gap-4">
            <NotificationPanel
              useMarkAllAsReadMutation={useMarkAllAsReadMutation}
              useUpdateNotificationMutation={useUpdateNotificationMutation}
              useGetNotificationsQuery={useGetNotificationsQuery}
              onSettings={handleSettingsRoute}
              user_id={user?.id}>
              {(unread_notifications) => (
                <Button type="icon" className="relative">
                  <Icon type="notification" />
                  {Boolean(unread_notifications) && (
                    <span className="absolute top-1 right-0 bg-primary rounded-full px-1 text-xs text-white">
                      {unread_notifications}
                    </span>
                  )}
                </Button>
              )}
            </NotificationPanel>

            <MobileHeaderUser
              isLoading={isUserLoading}
              avatar={user ? user.avatar : ''}
              username={user ? user.username : ''}
              isSubscribed={isSubscribed}
            />
          </div>
        </MobileHeader>
      )}

      {controlDisplay.isNotHome && (
        <>
          {controlDisplay.creatorProfile && (
            <div className="lg:block mb-3 lg:mb-0 mt-2">
              <CreatorProfile
                name={creator.name}
                avatar={creator.avatar}
                banner={creator.banner}
                notifications={creator?.notifications}
                badgeAmount={isBadgesReady ? badgesData.badgeAmount : 0}
                isLoading={isCreatorLoading}
                creatorId={creator.id}
                score={creator?.score}
                onBio={onOpenAbout}
                isAboutActive={currentTab === 'About'}
                openMessagePanel={openMessagePanel}
                isMessagesLoading={isCreatorLoading}
              />
            </div>
          )}

          {/* Tabs Desktop */}
          <div
            className={`gap-4 hidden lg:flex items-center pb-8 lg:items-end lg:justify-between lg:pb-4 lg:pt-4 bg-white z-50 sticky top-0 ${
              isAuthenticated ? 'px-2 lg:px-14' : ''
            }`}>
            <div className="flex w-full items-center justify-around gap-4 whitespace-nowrap lg:w-fit">
              {!controlDisplay.creatorProfile && (
                <div onClick={() => router.back()} className="flex items-center gap-4">
                  <Icon type="back" />
                </div>
              )}
              {filteredTabs.map((tab) => (
                <div
                  key={tab.id}
                  className="relative"
                  onClick={() => (isLoading ? null : handleTabClick(tab.id))}>
                  {tab.notificationGetter && tab.notificationGetter(notificationData) > 0 && (
                    <div
                      className={`w-4 h-4 p-1 rounded-full bg-primary text-white absolute -top-1 -right-1 flex items-center justify-center text-xs ${
                        isLoading && 'opacity-35 z-10'
                      }`}>
                      {tab.notificationGetter(notificationData)}
                    </div>
                  )}
                  <div
                    className={`min-w-fit flex items-center gap-1 bg-input rounded-lg py-2 px-4 text-note 
            ${localTab === tab.id ? 'text-primary' : ''}
            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    {tab.icon && (
                      <Icon
                        type={tab.icon}
                        className="w-4 h-4 mr-1"
                        stroke={localTab === tab.id ? '#FF600D' : '#707B8F'}
                      />
                    )}
                    {tab.id}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* Foooter Tabs Mobile */}
      {controlDisplay.isNotHome && (
        <div className="fixed bottom-0 w-full flex justify-between items-center bg-white shadow-xl shadow-black lg:hidden z-50 px-4 py-6">
          {filteredTabs.map((tab, idx) => (
            <div
              key={tab.id + idx}
              className={`relative w-[15%] h-5 flex items-end justify-center ${
                isLoading ? 'opacity-50' : ''
              }`}
              onClick={() => (isLoading ? null : handleTabClick(tab.id))}>
              {tab.notificationGetter && tab.notificationGetter(notificationData) > 0 && (
                <div className="w-4 h-4 p-1 rounded-full bg-primary text-white absolute -top-2 right-2 flex items-center justify-center text-xs">
                  {tab.notificationGetter(notificationData)}
                </div>
              )}

              <div className="min-w-fit flex items-center gap-1">
                {tab.icon && (
                  <Icon
                    type={tab.icon}
                    className={`w-5 h-5 mr-1 ${
                      isLoading && tab.id !== localTab ? 'animate-pulse' : ''
                    }`}
                    stroke={localTab === tab.id ? '#FF600D' : '#707B8F'}
                  />
                )}
              </div>
            </div>
          ))}

          {isAuthenticated && (
            <div
              className={`relative w-[15%] h-5 flex items-center justify-center ${
                isLoading ? 'opacity-50' : ''
              }`}
              onClick={() => {
                if (!isLoading) {
                  dispatch(setShowMessages(true));
                }
              }}>
              {creator?.notifications > 0 && (
                <div className="w-4 h-4 p-1 rounded-full bg-primary text-white absolute -top-2 right-2 flex items-center justify-center text-xs">
                  {!creator?.notifications
                    ? ''
                    : creator?.notifications > 99
                    ? '99+'
                    : creator?.notifications}
                </div>
              )}

              <div className="min-w-fit flex items-center gap-1">
                {
                  <Icon
                    type={'MessagesIcon'}
                    className={`w-4 h-4 mr-1 ${isLoading ? 'animate-pulse' : ''}`}
                    stroke={localTab === 'Message' ? '#FF600D' : '#707B8F'}
                  />
                }
              </div>
            </div>
          )}
        </div>
      )}
      <WithAuthModal
        creatorInfo={userInfo.creatorInfo}
        message={message || null}
        open={showAuthModal}
        onOpenChange={(open) => {
          setShowAuthModal(open);
          if (!open) clearInterception();
        }}
      />
      <div className={`${isAuthenticated && controlDisplay.isNotHome ? 'px-2 lg:px-14 ' : 'px-0'}`}>
        {children}
      </div>
    </div>
  );
};

export default Navigator;
