'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import { SideBar } from './_components/sidebar/sidebar';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { useLogout } from '@lib/hooks/use-logout';
import { cookieService } from '@lib/cookie.service';
import { useEffect, useState } from 'react';
import { useToast } from '@ui/components';
import { SocketEventsProvider } from '@cable/socket-provider';
import Events from './events';
import { useLazyGetCreatorsQuery } from '@lib/redux/api/creators-api';
import Navigator from './_components/navigate/Navigator';
import { useAuthSession } from '@lib/hooks/use-auth-session';
import PublicHeader from './_components/navigate/public-header';
import { twMerge } from 'tailwind-merge';
import Loading from 'app/loading';

export default function AppLayout({ children }: React.PropsWithChildren<{}>) {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuthSession();
  const [auth, setAuth] = useState(false);
  const [getCreators, { data: creators, isSuccess: isCreatorsLoaded }] = useLazyGetCreatorsQuery();
  const { data: user, isSuccess: isUserSuccess } = useGetUserQuery();
  const searchParams = useSearchParams();
  const errorMessage = searchParams.get('error');

  const router = useRouter();
  const onLogout = useLogout(errorMessage ? false : true);
  const toast = useToast();

  useEffect(() => {
    const token = searchParams.get('token');
    const refresh = searchParams.get('refresh');
    const id = searchParams.get('id');

    if (errorMessage) {
      toast(
        {
          title: 'Ooops',
          message: errorMessage,
        },
        {
          type: 'error',
        },
      );
      cookieService.clearAuthCookies();
    }

    if (token && refresh && id) {
      cookieService.setAuthCookies({
        accessToken: token,
        refreshToken: refresh,
        userId: id,
      });
      const inClaimProcess = JSON.parse(localStorage.getItem('badgeInfo'));
      if (inClaimProcess) {
        localStorage.removeItem('badgeInfo');
        router.push(`/badge/?creator=${inClaimProcess.creator}&badgeId=${inClaimProcess.badgeId}`);
      } else {
        router.push('/');
      }
    }

    setAuth(!cookieService.getAuthCookies().accessToken);

    if (
      !auth &&
      sessionStorage.getItem('messageUrl') &&
      sessionStorage.getItem('hasRunMessageEffect') === 'true'
    ) {
      router.push(sessionStorage.getItem('messageUrl'));
    }
  }, [
    searchParams.get('token'),
    searchParams.get('refresh'),
    searchParams.get('id'),
    searchParams.get('error'),
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getCreators();
    }
  }, [isAuthenticated]);

  if (auth) {
    if (errorMessage) {
      setTimeout(() => {
        onLogout();
      }, 3000);
    }
  }

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <SocketEventsProvider userId={user?.id}>
      <Events />
      <div
        className={twMerge(
          'flex h-full w-full min-h-screen',
          isAuthenticated ? 'flex-col lg:flex-row pb-8' : 'flex-col',
        )}>
        {!isAuthenticated && <PublicHeader />}
        {isAuthenticated && (
          <SideBar
            user={{
              name: user ? user.name : '',
              avatar: user ? user.avatar : '',
              username: user ? user.username : '',
              id: user ? user.id : '',
              isLoading: !isUserSuccess,
            }}
            creators={
              isCreatorsLoaded
                ? creators.map((creator) => ({
                    name: creator.name,
                    badgeAmount: creator.badges,
                    avatar: creator.avatar,
                    id: creator.id,
                    isNotifications: creator.notifications > 0,
                  }))
                : []
            }
            totalBadges={
              isCreatorsLoaded ? creators.reduce((acc, { badges }) => (acc += badges), 0) : 0
            }
            onLogout={onLogout}
          />
        )}
        <div className={`w-full ${isAuthenticated ? 'lg:w-[calc(100%-280px)]' : ''} ml-auto`}>
          {<Navigator>{children}</Navigator>}
        </div>
      </div>
    </SocketEventsProvider>
  );
}
