'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import {
  useGetBadgesInfiniteQuery,
  badgesAdapter,
  badgesSelector,
  allBadgesAdapter,
  allBadgesSelector,
  useGetBadgesByAuthorQuery,
} from '@lib/redux/api/badges-api';
import { useGetCreatorSocialProfileQuery, useGetCreatorsQuery } from '@lib/redux/api/creators-api';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { useEffect, useMemo, useState } from 'react';
import { ICreator } from '../../creator.typings';
import { useAppSelector } from '@lib/redux/store';
import { cookieService } from '@lib/cookie.service';
import {
  conversationsAdapter,
  conversationsSelector,
  fanConversationsAdapter,
  fanConversationsSelector,
  useGetCommunityConversationsQuery,
  useGetFanCommunityConversationsQuery,
} from '@lib/redux/api/conversations-api';
import { useGetEngagementScoreQuery } from '@lib/redux/api/engagement-api';
import { ICommunityConversationParams, IFanCommunityConversationQueryParams } from '@ui/components';
import { useGetNotificationByTypeQuery } from '@lib/redux/api/notification-api';
import { useTaskCompletionStatus } from '@lib/hooks/use-complete';
import { useIsSubscribedToCreator } from '@lib/hooks/use-isSubscribed-to-creator';
import { useOnboardingState } from 'app/(app)/creator/onboarding/onboarding.state';
import { useAuthSession } from '@lib/hooks/use-auth-session';
import { useAppDispatch } from '@lib/redux/store';
import { setCreatorInfo } from '@lib/redux/features/user';
const emptyCreator: ICreator = {
  name: '',
  id: '',
  banner: '',
  avatar: '',
  notifications: 0,
  hasArchivedMessages: false,
  fans: 0,
  badges: 0,
  membershipSettings: {
    unlockPrizeBadgeAccess: false,
  },
  monetizationSettings: {
    donationEnabled: false,
  },
};

export function useNavgatorState(creatorId: string) {
  const { isAuthenticated } = useAuthSession();
  const router = useRouter();
  const params = useSearchParams();
  const currentFilter = useAppSelector((state) => state.badges.currentFilter);
  const [page, setPage] = useState(0);
  const [pageAll] = useState(0);
  const [prevCreator, setPrevCreator] = useState(null);
  const userId = cookieService.getUserId();
  const { onPatchTask } = useOnboardingState();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const dispatch = useAppDispatch();
  const [communityQuery] = useState<IFanCommunityConversationQueryParams>({
    conversation_type: 'community',
    fan_id: userId,
    items_per_page: 10,
    page: 1,
    creator_id: creatorId,
  });
  const [publicCommunityQuery] = useState<ICommunityConversationParams>({
    conversation_type: 'community',
    items_per_page: 10,
    page: 1,
    creator_id: creatorId,
  });
  const {
    data: creatorDataFromFilterQuery,
    isSuccess: isCreatorsLoaded,
    isLoading: isCreatorLoading,
    isError,
  } = useGetCreatorsQuery(undefined, {
    skip: !creatorId || !isAuthenticated,
    selectFromResult: (res) => {
      const creator = res.isSuccess ? res.data.find((creator) => creator.id === creatorId) : null;
      return {
        ...res,
        data: creator ?? emptyCreator,
        isError: res.isSuccess ? !creator : res.isError,
        isSuccess: !!creator,
      };
    },
  });

  const { data: creatorSocialProfile } = useGetCreatorSocialProfileQuery(creatorId, {
    skip: !creatorId,
  });

  const creator: ICreator = useMemo(() => {
    if (!isAuthenticated) {
      const info = {
        avatar: creatorSocialProfile?.creatorInfo?.avatarURL,
        banner: creatorSocialProfile?.creatorInfo?.imageURL,
        name: creatorSocialProfile?.creatorInfo?.displayName,
        id: creatorSocialProfile?.creatorInfo?._id,
        notifications: 0,
        communityBadge: creatorSocialProfile?.communityBadge[0],
        hasArchivedMessages: false,
        fans: creatorSocialProfile?.creatorInfo?.totalFans,
        badges: 0,
        membershipSettings: {
          unlockPrizeBadgeAccess:
            creatorSocialProfile?.creatorInfo?.membershipSettings?.unlockPrizeBadgeAccess,
        },
        monetizationSettings: {
          donationEnabled: creatorSocialProfile?.creatorInfo?.monetizationSettings?.donationEnabled,
        },
      };
      return info;
    }
    return creatorDataFromFilterQuery;
  }, [creatorDataFromFilterQuery, creatorSocialProfile?.creatorInfo, isAuthenticated]);

  const checklist = useTaskCompletionStatus(creatorId ? creatorId : creator?.id, 'claim_badge');
  const profileChecklist = useTaskCompletionStatus(creatorId ? creatorId : creator?.id, 'profile');

  const { data: newPostNotifications, refetch: reFetchnewPostNotifications } =
    useGetNotificationByTypeQuery(
      { user_id: userId, type: 'post', creator_id: creatorId },
      { skip: !userId || !creatorId || !isAuthenticated },
    );

  const { data: newContentNotifications } = useGetNotificationByTypeQuery(
    { user_id: userId, type: 'notebook', creator_id: creatorId },
    { skip: !userId || !creatorId || !isAuthenticated },
  );

  const isSubscribed = useIsSubscribedToCreator(userId, creatorId);
  const { data: allBadgesData, isLoading: allBadgesDataLoading } = useGetBadgesByAuthorQuery(
    {
      creatorId: creatorId,
      page: creator.id === prevCreator ? pageAll : 0,
      pageSize: 12,
      order: currentFilter.order,
      rarity: currentFilter.rarity,
    },
    {
      skip: !creatorId || !isAuthenticated,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            allCreatorBadges: allBadgesSelector.selectAll(
              data ? data.allCreatorBadges : allBadgesAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const {
    data: badgesData,
    isSuccess: isBadgesLoaded,
    isLoading: isBadgesLoading,
    isUninitialized,
    refetch,
  } = useGetBadgesInfiniteQuery(
    {
      creatorId: creatorId,
      page: creator.id === prevCreator ? page : 0,
      pageSize: 12,
    },
    {
      skip: !creatorId || !isAuthenticated,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            badges: badgesSelector.selectAll(data ? data.badges : badgesAdapter.getInitialState()),
          },
          ...params,
        };
      },
    },
  );
  const {
    data: communityConversationData,
    isSuccess: isCommunityConversationSuccess,
    isLoading: isCommunityConversationLoading,
  } = useGetFanCommunityConversationsQuery(
    { ...communityQuery, creator_id: creatorId },
    {
      skip: !creatorId || !isAuthenticated,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            conversations: fanConversationsSelector.selectAll(
              data ? data.conversations : fanConversationsAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const {
    data: publicCommunityConversations,
    isSuccess: isPublicCommunityConversationSuccess,
    isLoading: isPublicCommunityConversationLoading,
  } = useGetCommunityConversationsQuery(
    { ...publicCommunityQuery, creator_id: creatorId },
    {
      skip: !creatorId,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            conversations: conversationsSelector.selectAll(
              data ? data.conversations : conversationsAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const communities = useMemo(() => {
    return communityConversationData?.conversations;
  }, [communityConversationData]);

  const publicCommunities = useMemo(() => {
    return publicCommunityConversations?.conversations;
  }, [publicCommunityConversations]);

  const {
    data: engagementScoreData,
    error: engagementScoreError,
    refetch: refetchEngagementScore,
    isUninitialized: isUninitializedEngagement,
  } = useGetEngagementScoreQuery(
    { fan_id: userId, creator_id: creatorId },
    { skip: !creatorId || !userId || !isAuthenticated },
  );

  useEffect(() => {
    if (!creatorId) {
      return;
    }

    if (prevCreator !== creator.id) {
      setPage(0);
      setPrevCreator(creator.id);

      if (!isUninitialized) {
        refetch();
      }

      if (creatorId && userId && !isUninitializedEngagement) {
        refetchEngagementScore();
      }
    }
  }, [creatorId, creator.id]);

  useEffect(() => {
    if (!creatorId || isError) {
      return;
    }
  }, [creatorId, onBack, isError]);

  function onBack() {
    router.back();
  }

  const [isShowMessages, setShowMessages] = useState(false);
  const [isMessagesLoading, setMessagesLoading] = useState(true);

  function onShowMessages() {
    setShowMessages(true);
  }

  function onHideMessages() {
    setShowMessages(false);
  }

  useEffect(() => {
    if (!isAuthenticated) {
      setMessagesLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (checklist && !checklist?.completed && checklist?._id) {
      onPatchTask({
        task_id: checklist._id,
        completed: true,
        creator_id: creatorId ?? creator?.id,
      }).catch((error) => console.error('Error patching onboarding task:', error));
    }
  }, [checklist, creatorId]);

  useEffect(() => {
    if (
      user &&
      user.avatar &&
      profileChecklist &&
      !profileChecklist?.completed &&
      profileChecklist?._id
    ) {
      onPatchTask({
        task_id: profileChecklist._id,
        completed: true,
        creator_id: creatorId ?? creator?.id,
      }).catch((error) => console.error('Error patching onboarding task:', error));
    }
  }, [profileChecklist, creatorId, user]);

  // handle message pop up from email link
  useEffect(() => {
    const messageId = params.get('messageId');
    if (!messageId) return;

    const hasRunMessageEffect = sessionStorage.getItem('hasRunMessageEffect');

    const handleEffect = () => {
      if (!userId) {
        sessionStorage.setItem('messageUrl', window.location.href);
        sessionStorage.setItem('hasRunMessageEffect', 'true');
      } else if (hasRunMessageEffect !== 'done') {
        if (isCreatorsLoaded) {
          setShowMessages(true);
          sessionStorage.setItem('hasRunMessageEffect', 'done');
        }
      }
    };

    if (hasRunMessageEffect !== 'done') {
      handleEffect();
    }
  }, [params, userId, isCreatorsLoaded]);

  // Move the dispatch out of render phase
  const creatorInfo = useMemo(() => {
    if (creator?.communityBadge && creator?.name) {
      return { communityBadge: creator.communityBadge, name: creator.name };
    }
    return null;
  }, [creator?.communityBadge, creator?.name]);

  useEffect(() => {
    if (creatorInfo) {
      dispatch(setCreatorInfo(creatorInfo));
    }
  }, [creatorInfo, dispatch]);

  return {
    isCreatorReady: isCreatorsLoaded && !!creator,
    isBadgesReady: isBadgesLoaded && !!badgesData,
    creator: {
      name: creator ? creator.name : 'Loading...',
      avatar: creator ? creator.avatar : '',
      banner: creator ? creator.banner : '',
      id: creatorId,
      notifications: creator ? creator.notifications : 0,
      hasArchivedMessages: creator ? creator.hasArchivedMessages : false,
      fans: creator ? creator.fans : 0,
      badges: creator ? creator.badges : 0,
      score: !engagementScoreError ? engagementScoreData : null,
      membershipSettings: creator ? creator.membershipSettings : null,
      monetizationSettings: creator ? creator.monetizationSettings : null,
    },
    isCreatorLoading: isCreatorLoading,
    isMessagesLoading,
    setMessagesLoading,
    badgesData,
    allBadgesData,
    isShowMessages,
    user,
    isUserLoading,
    isBadgesLoading: isBadgesLoading,
    isAllBadgesLoading: allBadgesDataLoading,
    onHideMessages,
    onBack,
    onShowMessages,
    isCommunityConversationSuccess,
    isCommunityConversationLoading,
    communitySets: isCommunityConversationSuccess ? communities : [],
    publicCommunitySets: isPublicCommunityConversationSuccess ? publicCommunities : [],
    newPostNotifications,
    newContentNotifications,
    reFetchnewPostNotifications,
    isSubscribed,
    isPublicCommunityConversationSuccess,
    isPublicCommunityConversationLoading,
  };
}
