import { Icon } from '@ui/components';
import { twMerge } from 'tailwind-merge';
import LoadingComponent from '@ui/components/atoms/spinner/loading';

interface Props {
  className?: string;
}
const Loading = (props: Props) => {
  return <LoadingComponent {...props} />;
};

export default Loading;
